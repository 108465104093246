import React, { useContext } from "react"
import { GlobalDataContext } from "../context/context"
import BaseLayout from "../components/global/BaseLayout"
import Modal from "../components/Home/Modal"
import HeroSection from "../components/Home/HeroVideo"
import BlockContentPrincipal from "../components/block/BlockContent_1"
import CallToActionBlockTopAbout from '../components/callToAction/CallToAction_1'
import BlockContentAbout from "../components/block/BlockContent_2"
import ValuesContent from '../components/values/Values_1'
import Directories from "../components/Home/Directories";
import VideoPromocional from "../components/global/VideoPromocional";
import ServicesHome from '../components/Services/ServicesHome_1'
import CallToAction2 from '../components/callToAction/CallToAction_2'
import Map from "../components/Contact/MapContent"
import Form from "../components/Contact/Form"
import ContentServices from "../components/Services/ContentServices"
import bankImg from '../assets/image/bank.png'

// para agregar la frase de los años de experiencia o la licencia agregar sloganPrincipal={true}
// para agregar la lista de about agregar listsAbout={true}
// para agregar la lista de servicios agregar listsServices={true}

function Home() {

  const { rpdata } = useContext(GlobalDataContext);

  return (
    <BaseLayout PageName="Home">
      <div className="md:max-w-full w-full">

        <Modal />
        <HeroSection
          title={rpdata?.dbSlogan?.[0]?.slogan}
          texts={rpdata?.dbValues?.[0].description}
          urlVideo={"https://firebasestorage.googleapis.com/v0/b/videos2-986b9.appspot.com/o/Sacramento%2C%20CA.mp4?alt=media&token=12152f90-8f08-4851-bce9-2ba9f82b6b82"}
          bgImg={rpdata?.stock?.[4]}
          bgImg1={rpdata?.stock?.[5]}
          bgImg2={rpdata?.stock?.[6]}
          bgImg3={rpdata?.stock?.[7]}
        />


        <BlockContentPrincipal
          sloganPrincipal
          listServices
          texts={rpdata?.dbHome?.[0].text}
          images={rpdata?.stock?.[11]}
        />

        <div className="h-screen w-full bg-cover bg-center bg-no-repeat md:-mb-32 -mb-12 flex flex-col justify-center items-center"
          style={{ backgroundImage: `url(${bankImg})` }}
        >
          <div className="bg-white p-8 rounded-lg shadow-lg text-center w-4/5 md:max-w-2xl">
            <h2 className=" font-bold text-gray-800 mb-4">
              We Offer Financing!
            </h2>
            <p className="text-lg text-gray-600 mb-6">
              Now you can finance your projects with us. Click below to fill out the
              application and get more details about financing options.
            </p>
            <a
              href="https://www.hfsfinancial.net/promo/636fe161f68db9391226e72b/"
              target="_blank"
              rel="noopener noreferrer"
              className="inline-block bg-green-500 text-white text-lg font-semibold py-3 px-6 rounded-full shadow-lg hover:bg-green-600 transition-colors"
            >
              Fill Out Application
            </a>
            {/* <a
              href="https://www.hfsfinancial.net/promo/636fe161f68db9391226e72b/"
              target="_blank"
              rel="noreferrer"
              className="w-1/5 h-auto rounded-2xl bg1 px-4 py-4 text-white text-center font-bold text-lg my-5 mx-auto">
              We offer financing
            </a> */}
          </div>
        </div>




        {/* call to action va junto con el blockContent_2 */}
        <CallToActionBlockTopAbout
          title={rpdata?.dbSlogan?.[3]?.slogan}
          texts={rpdata?.dbHome?.[1].text}
          images={rpdata?.stock?.[4]}
        />
        <BlockContentAbout
          title={'a little about us'}
          texts={rpdata?.dbAbout?.[0]?.text}
          listAbout
          images={rpdata?.stock?.[10]}
          bgImages={rpdata?.stock?.[17]}
        />

        <ValuesContent
          mission={rpdata?.dbValues?.[0].description}
          vision={rpdata?.dbValues?.[1].description}
          whychoose={rpdata?.dbValues?.[2].description}
          img1={rpdata?.stock?.[6]}
          img2={rpdata?.stock?.[5]}
          img3={rpdata?.stock?.[17]}
        />

        <Directories />

        {/* video promocional */}
        {
          rpdata?.videosPromo?.[0]?.activo ?
            <VideoPromocional
              title={rpdata?.dbSlogan?.[2]?.slogan}
              text={rpdata?.dbAbout?.[1]?.text}
              linkVideo={`${rpdata?.videosPromo?.[0].link}`}
              image={`${rpdata?.videosPromo?.[0].img}`}
              vimeoVideo={true}
            />
            : null
        }

        {/* validacion si es onepages */}
        {
          rpdata?.simpleWidgets?.[3]?.activo ?
            <ContentServices />
            :
            <ServicesHome />
        }

        <CallToAction2
          title={rpdata?.dbSlogan?.[4]?.slogan}
          subtitle={rpdata?.dbSlogan?.[0]?.slogan}
          phone={rpdata?.dbPrincipal?.phones?.[0]?.phone}
          bgImages={rpdata?.stock?.[15]}
        />

        {/* our reviews */}
        {
          rpdata?.reviews?.isHomeOnly === true ?
            <div className="w-4/5 mx-auto py-[100px]">
              <h1 className="pb-10 text-center">{rpdata?.labels?.general?.titleReviews}</h1>
              <div className={`${rpdata?.reviews?.links?.[0]}`}></div>
            </div>
            : null
        }

        <Map
          // para cambiar el svgclas busque la clase en el archivo svgStyles
          svgClass={'svgMap2'}
        />

        <div className='w-full flex justify-center mb-20'>
          <div className='max-w-2xl p-5 mx-5 bg-white shadow-lg '
            data-aos="flip-down"
            data-aos-duration="2000"
          >
            <h2 className='text-center'>Send Us A Message</h2>
            <div className='p-4'>
              <Form />
            </div>
          </div>
        </div>

      </div>
    </BaseLayout>
  );
}

export default Home;
